<script setup>
import { state } from '@/store/store.js';
</script>

<template>
    <div id="container">
        <div id="top">
            <img src="@/assets/logo.svg" alt="logo">
        </div>
        <div id="bottom">
            <div id="form">
                <div class="input-group">
                    <label for="email">Email</label>
                    <input v-model="email" type="text" placeholder="john.smith@victoryleads.net" id="email">
                </div>

                <div id="return-to-login">
                    <a href="/login">Return to Login</a>
                </div>

                <div v-if="errorMessage" id="error-message-container">
                    <img alt="alert-triangle" src="@/assets/alert-triangle.svg">
                    <span id="error-message">
                        {{ errorMessage }}
                    </span>
                </div>

                <button id="reset" @click="reset()">
                    <span>Reset Password</span>
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'ForgotPassword',
    components: {},
    data: () => ({
        email: "",
        errorMessage: ""
    }),
    async mounted() {},
    methods: {
        async reset() {
            try {
                let res = await state.api.call("forgotPassword", { email: this.email });
                alert(res);
                window.location.href = "/";
            } catch (error) {
                console.log(error);
                this.errorMessage = error.errorMessage;
            }
        }
    }
}
</script>

<style scoped>
#error-message-container {
    margin-top: 12px;

    display: flex;

}

#error-message-container img {
    margin-right: 12px;
}

#error-message {
    color: #C56327;
    text-align: left;
}

.input-group {
    /* display: flex;
    flex-direction: column; */
    width: 100%;
    height: 72px;

    margin-bottom: 20px;
}

.input-group label {
    height: 28px;
    padding-left: 16px;

    font-size: 12px;
    font-weight: 600;
    color: #707070;
    line-height: 16px;

    display: flex;
    align-items: center;

    user-select: none;
    /* align-self: flex-start; */
    /* justify-content: center; */

}

.input-group input {
    height: 22px;
    padding: 10px 12px;

    width: calc(100% - 26px);

    border-radius: 4px;
    border: 1px solid #CFD8DC;

    /* flex-grow: 1; */
}

.input-group img {
    position: absolute;
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    width: 24px;
    height: 24px;
}

input:focus::placeholder {
    color: transparent;
}

input::placeholder {
    user-select: none;
}

#form i {
    margin-left: -30px;
    cursor: pointer;
    color: var(--btn-blue-dark);
}

#password-hidden {
    margin-left: -14px;
}

#password-displayed {
    margin-left: -14px;
}

#container {
    display: flex;
    flex-direction: column;
}

#top {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    height: 23vh;
    border-bottom: 2px solid var(--color-orange);
}

#top img {
    width: 318px;
}

#bottom {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-grow: 1;
}

#form {
    width: 360px;
    margin-top: 3vh;
}

#return-to-login {
    display: flex;
    justify-content: flex-end;
    margin-top: 2vh;

    font-size: 14px;
}

button {
    display: block;
    margin-top: 24px;
    width: 100%;
    height: 44px;

    border-radius: 10px;

    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}


#reset {
    background-color: var(--btn-blue-dark);
    border: 1px solid var(--btn-blue-dark);
}

#reset span {
    margin-right: 6px;
}

button:focus {
    outline: 1px solid black !important;
}

#email::placeholder {
    color: #CFD8DC;
}
</style>
  