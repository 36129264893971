import { reactive } from 'vue';
import PortalAPI from '../api.js';

// Reactive object for data that will be written to DB
export const store = reactive({
	count: 0,
	increment() {
		this.count++;
	}
});

// Reactive object to control current state of Vue app
export const state = reactive({
	api: null,
	isAPIProcessing: false,
	flashMessages: new Map(),
	flash(message) {
		/**
		 * message = {type,status,message,time}
		 */
		if (!message.time) {
			message.time = 3000;
		}
		let randomID = Math.floor(Math.random() * Date.now());
		// message.type = `-${message.type}`;
		this.flashMessages.set(randomID, message);
		let mapMessage = this.flashMessages.get(randomID);
		setTimeout(() => {
			mapMessage.status = '-show';
		}, 10);
		setTimeout(() => {
			mapMessage.status = '';
			setTimeout(() => {
				this.flashMessages.delete(randomID);
			}, 300);
		}, message.time);
	},
	toMoney(value) {
		// USD format with commas
		return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
	},
	unixToDate(unix) {
		const date = new Date(unix);

		const day = date.getDate();
		const month = date.toLocaleString('en-US', { month: 'short' });
		const year = date.getFullYear();
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const period = hours < 12 ? 'am' : 'pm';

		const formattedString = `${day} ${month} ${year} ${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
		return formattedString;

		// const options = {
		//     day: 'numeric',
		//     month: 'short',
		//     year: 'numeric',
		//     hour: 'numeric',
		//     minute: '2-digit',
		//     hour12: true,
		//     comma: false
		//   };
		//   const formattedString = date.toLocaleString('en-US', options);
		//   return formattedString.replace(/,/g, '');
	},
	user: {
		rid: '',
		firstName: '',
		lastName: '',
		addressPersonal: '',
		addressBusiness: '',
		addressStreet: '',
		addressCity: '',
		addressState: '',
		addressZip: '',
		phonePersonal: '',
		phoneBusiness: '',
		emailPersonal: '',
		emailBusiness: '',
		cannotPurchase: true,
		sendSMS: false
	},
	isUserLoggedIn: false,
	hamburgerMenu: {
		display: false,
		toggle() {
			this.display = !this.display;
		},
	},
	leadsDetails: {
		display: false,
		toggle() {
			this.display = !this.display;
		},
	},
	currentView: ''
});

state.api = new PortalAPI(state);
