<script setup>
    import { state } from '../store/store.js';
</script>

<script>
	export default {
		name: 'NPNModal',
		mounted() {
			if (state.user.rid && !state.user.npn) {
				document.querySelector('dialog._npn').showModal();
			}
		},
		data: () => ({
			npn: ''
		}),
		methods: {
			async saveNPN() {
				if (!this.npn) {
					state.flash({
						type: 'error',
						message: 'Please make sure to provide a valid NPN'
					});
				}else {
					let user = await state.api.call("updateNPN", {
						uid: state.user.rid,
						npn: this.npn.trim()
					});
					document.querySelector('dialog._npn').close();
					if (user && user.rid) {
						state.user = user;
						state.flash({
							type: 'success',
							message: 'NPN saved successfully'
						});
					}else {
						state.flash({
							type: 'error',
							message: 'Something went wrong. Please refresh the page and try again.'
						});
					}
				}
			}
		},
	};
</script>

<template>
	<dialog class="_npn">
		<div>
			<p>We need your NPN for our records. Please enter it below: </p>
			<div class="input-group">
				<label>NPN #</label>
				<input v-model="npn" type="text" placeholder="######" id="npn">
			</div>
			<footer>
				<button @click="saveNPN()" class="btn-blue-light">Save</button>
			</footer>
		</div>
	</dialog>
</template>

<style scoped>
	.input-group input {
		height: auto;
	}
</style>
