export const leadsData = [
    {
        id: 1,
        fullName: 'John Doe',
        state: 'AL',
        county: 'Autauga County',
        status: 'VF',
        dateOfPurchase: '15 May 2023 8:00 am',
        price: "$50"
    },
    {
        id: 2,
        fullName: 'Jane Doe',
        state: 'AL',
        county: 'Baldwin County',
        status: 'VF',
        dateOfPurchase: '22 May 2023 11:45 am',
        price: "$55"
    },
    {
        id: 3,
        fullName: 'John Smith',
        state: 'AL',
        county: 'Barbor County',
        status: 'VF',
        dateOfPurchase: '11 May 2023 8:00 am',
        price: "$50"
    },
    {
        id: 4,
        fullName: 'Jane Smith',
        state: 'AL',
        county: 'Blount County',
        status: 'VF',
        dateOfPurchase: '15 April 2023 2:25 pm',
        price: "$22.27"
    },
    {
        id: 5,
        fullName: 'John Doe',
        state: 'AL',
        county: 'Bullock County',
        status: 'V2',
        dateOfPurchase: '25 April 2023 8:00 am',
        price: "$340"
    },
    {
        id: 6,
        fullName: 'Jane Doe',
        state: 'AL',
        county: 'Butler County',
        status: 'VF',
        dateOfPurchase: '1 May 2023 7:45 am',
        price: "$60.75"
    },
    {
        id: 7,
        fullName: 'John Smith',
        state: 'AL',
        county: 'Tallaposa',
        status: 'V2',
        dateOfPurchase: '7 May 2023 8:00 am',
        price: "$50.25"
    },
    {
        id: 8,
        fullName: 'Jane Smith',
        state: 'AL',
        county: 'Tallaposa',
        status: 'V2',
        dateOfPurchase: '10 May 2023 11:00 am',
        price: "$50"
    },
    {
        id: 9,
        fullName: 'John Doe',
        state: 'AL',
        county: 'Tallaposa',
        status: 'VB',
        dateOfPurchase: '9 April 2023 10:30 am',
        price: "$50"
    }
];