<template>
    <div>
      <h1>About Us</h1>
      <!-- <HelloWorld msg="My Leads component"/> -->
  
      <!-- <button popovertarget="my-popover">Open Popover</button>
      <div popover id="my-popover">Greetings, one and all!</div> -->
  
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  
  export default {
    name: 'AboutUs',
    components: {
      // HelloWorld
    }
  }
  </script>
  