<template>
    <div id="container">
        <div id="header-row">
            <span class="header-title">Notifications</span>
            <button class="btn-white">
                <img alt="clock icon" src="@/assets/clock.svg" style="margin-right: 8px;" @click="$router.replace('/my-account/')">
                Waitlist
            </button>
            <button class="btn-white" id="notifications-channels-setup-btn">
                Notifications Channels Setup
                <img alt="settings icon" src="@/assets/settings.svg" style="margin-left: 8px;">
            </button>
        </div>

        <div id="notifications-wrapper">
            <div class="notification" :class="{ 'notification-read' : notifOne}">
                <span class="notification-text">
                    State <span style="font-weight: 600;">Alabama</span>, county <span style="font-weight: 600;">Chilton</span> Became Available.
                    <a href="">Subscribe.</a>
                </span>
                <button class="mark-as-read-btn btn-white" @click="notifOne = !notifOne">
                    <span v-if="notifOne">Mark as Unread</span>
                    <span v-else>Mark as Read</span>
                </button>
            </div>
        </div>

        <div id="notifications-wrapper">
            <div class="notification" :class="{ 'notification-read' : notifTwo}">
                <span class="notification-text">
                    State <span style="font-weight: 600;">Alabama</span>, county <span style="font-weight: 600;">Chilton</span> has leads Available.
                    <a href="">Purchase Leads.</a>
                </span>
                <button class="mark-as-read-btn btn-white" @click="notifTwo = !notifTwo">
                    <span v-if="notifTwo">Mark as Unread</span>
                    <span v-else>Mark as Read</span>
                </button>
            </div>
        </div>

        <div id="notifications-wrapper">
            <div class="notification" :class="{ 'notification-read' : notifThree}">
                <span class="notification-text">
                    <span style="font-weight: 600;">4 VF Leads</span> have just appeared in your leads.
                    <a href="/my-leads" @click.stop="$router.replace('/my-leads')">Goto My Leads.</a>
                </span>
                <button class="mark-as-read-btn btn-white" @click="notifThree = !notifThree">
                    <span v-if="notifThree">Mark as Unread</span>
                    <span v-else>Mark as Read</span>
                </button>
            </div>
        </div>
   
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'Notifications',
    components: {
        // HelloWorld
    },
    data() {
        return {
            // hard coded example notifications, will need to be updated to use real data
            // marked as read status of our 3 example notifications:...
            notifOne: false,
            notifTwo: false,
            notifThree: false,
        }
    },
}
</script>

<style scoped>
.notification {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 64px;
    width: 100%;
    margin-top: 12px;

    background: white;
    border: 1px solid #CFD8DC;
}

.notification-read {
    background: var(--color-content-bg);
}

.notification-text {
    margin-left: 18px;
}

.mark-as-read-btn {
    margin-right: 18px;
}

#notifications-wrapper {
    display: flex;
    flex-direction: column;
}

#notifications-channels-setup-btn {
    margin-left: 16px;
}

#header-row {
    display: flex;
    align-items: center;

}

.header-title {
    font-size: 28px;
    font-weight: 300;
    color: var(--btn-blue-dark);
    margin-right: auto;
}

#container {
    padding: 24px;
}
</style>