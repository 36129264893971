<script setup>
import { state } from '@/store/store.js';
</script>

<template>
    <div id="container">
        <div id="top">
            <img src="@/assets/logo.svg" alt="logo">
        </div>
        <div id="bottom">
            <div id="form">
                <div class="row">
                    <div class="input-group">
                        <label for="email">Email Address</label>
                        <input v-model="email" type="text" placeholder="" id="email" disabled>
                    </div>
                </div>

                <div class="row">
                    <div class="input-group" v-if="displayPassword1 == false">
                        <label for="password1-hidden">Password</label>
                        <input v-model="password1" type="password" placeholder="" id="password1-hidden" tabindex="11" minlength="8" required>
                        <i @click="toggleDisplayPasswordOne($event)" class="bi bi-eye"></i>
                    </div>
                    <div class="input-group" v-else>
                        <label for="password1-displayed">Password</label>
                        <input v-model="password1" type="text" placeholder="" id="password1-displayed" tabindex="11" minlength="8" required>
                        <i @click="toggleDisplayPasswordOne($event)" class="bi bi-eye-slash"></i>
                    </div>
                </div>

                <div class="row">
                    <div class="input-group" v-if="displayPassword2 == false">
                        <label for="password2-hidden">Password</label>
                        <input v-model="password2" type="password" placeholder="" id="password2-hidden" tabindex="12" minlength="8" required>
                        <i @click="toggleDisplayPasswordTwo($event)" class="bi bi-eye"></i>
                    </div>
                    <div class="input-group" v-else>
                        <label for="password2-displayed">Password</label>
                        <input v-model="password2" type="text" placeholder="" id="password2-displayed" tabindex="12" minlength="8" required>
                        <i @click="toggleDisplayPasswordTwo($event)" class="bi bi-eye-slash"></i>
                    </div>
                </div>

                <div v-if="errorMessage" id="error-message-container">
                    <img alt="alert-triangle" src="@/assets/alert-triangle.svg">
                    <span id="error-message">
                        {{ errorMessage }}
                    </span>
                </div>

                <button id="reset" @click="reset()">
                    <span>Reset Password</span>
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'ResetPassword',
    components: {},
    data: () => ({
        email: '',
        key: '',
        password1: '',
        password2: '',
        displayPassword1: false,
        displayPassword2: false,
        errorMessage: ''
    }),
    async mounted() {
        let params = new URLSearchParams(window.location.search);
        this.email = params.get('email');
        this.key = params.get('key');
        
        //Obfuscate URL
        let cleanURL = window.location.href.replace(window.location.search,'');
        history.pushState({}, '', cleanURL);
    },
    methods: {
        async reset() {
            try {
                if (this.password1 != this.password2) {
                    this.errorMessage = 'Passwords do not match';
                    return;
                }else if (!this.password1 || !this.password2) {
                    this.errorMessage = "Passwords cannot be blank";
                } else {
                    let res = await state.api.call("resetPassword", {
                        email: this.email,
                        password: this.password1,
                        key: this.key
                    });
                    if (res.errcode === 0) {
                        alert('Password reset succesfully. Please try to login again.')
                        window.location.href = "/";
                    }else {
                        console.log(res);
                    }
                }
            } catch (error) {
                console.log(error);
                this.errorMessage = error.errorMessage;
            }
        },
        toggleDisplayPasswordOne(event) {
            event.stopPropagation();
            event.preventDefault();

            this.displayPassword1 = !this.displayPassword1;
        },
        toggleDisplayPasswordTwo(event) {
            event.stopPropagation();
            event.preventDefault();

            this.displayPassword2 = !this.displayPassword2;
        }
    }
}
</script>

<style scoped>
#error-message-container {
    margin-top: 12px;

    display: flex;

}

#error-message-container img {
    margin-right: 12px;
}

#error-message {
    color: #C56327;
    text-align: left;
}

.input-group {
    /* display: flex;
    flex-direction: column; */
    width: 100%;
    height: 72px;
    position: relative;

    margin-bottom: 20px;
}

.input-group label {
    height: 28px;
    padding-left: 16px;

    font-size: 12px;
    font-weight: 600;
    color: #707070;
    line-height: 16px;

    display: flex;
    align-items: center;

    user-select: none;
    /* align-self: flex-start; */
    /* justify-content: center; */

}

.input-group input {
    height: 22px;
    padding: 10px 12px;
    width: calc(100% - 24px);
    border-radius: 4px;
    border: 1px solid #CFD8DC;

}

.input-group img {
    position: absolute;
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    width: 24px;
    height: 24px;
}

input:focus::placeholder {
    color: transparent;
}

input::placeholder {
    user-select: none;
}

#form i {
    cursor: pointer;
    color: var(--btn-blue-dark);
    position: absolute;
    top: calc(50% + 14px);
    right: 15px;
    transform: translateY(-50%);
    z-index: 2;
}

#password-hidden {
    margin-left: -14px;
}

#password-displayed {
    margin-left: -14px;
}

#container {
    display: flex;
    flex-direction: column;
}

#top {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    height: 23vh;
    border-bottom: 2px solid var(--color-orange);
}

#top img {
    width: 318px;
}

#bottom {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-grow: 1;
}

#form {
    width: 360px;
    margin-top: 3vh;
}

button {
    display: block;
    margin-top: 24px;
    width: 100%;
    height: 44px;

    border-radius: 10px;

    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}


#reset {
    background-color: var(--btn-blue-dark);
    border: 1px solid var(--btn-blue-dark);
}

#reset span {
    margin-right: 6px;
}

button:focus {
    outline: 1px solid black !important;
}

#email::placeholder {
    color: #CFD8DC;
}
</style>
  