<script setup>
import { state } from '@/store/store.js';
</script>

<template>
    <div id="container">
        <div id="top">
            <img src="@/assets/logo.svg" alt="logo">
        </div>
        <div id="bottom">
            <form class="form">

                <div style="margin-top: 24px;"></div>

                <div class="row">
                    <div class="input-group">
                        <label for="first-name">First Name</label>
                        <input v-model="user.firstName" type="text" placeholder="" id="first-name" tabindex="1" required>
                    </div>
                    <div class="input-group">
                        <label for="address-personal">Street Address</label>
                        <input v-model="user.addressStreet" type="text" placeholder="" id="address-personal" tabindex="5" required>
                    </div>

                    <div class="input-group">
                        <label for="phone-personal">Phone Personal (used for notifications)</label>
                        <input v-model="user.phonePersonal" @input="formatPhoneNumber('phonePersonal')" type="tel" placeholder="" id="phone-personal" tabindex="9" required>
                    </div>
                    
                </div>

                <div class="row">
                    <div class="input-group">
                        <label for="last-name">Last Name</label>
                        <input v-model="user.lastName" type="text" placeholder="" id="last-name" tabindex="2" required>
                    </div>
                    <div class="input-group">
                        <label for="address-city">Address City</label>
                        <input v-model="user.addressCity" type="text" placeholder="" id="address-city" tabindex="6" required>
                    </div>
                    <div class="input-group">
                        <label for="phone-business">Phone Business</label>
                        <input v-model="user.phoneBusiness" @input="formatPhoneNumber('phoneBusiness')" type="tel" placeholder="" id="phone-business" tabindex="10">
                    </div>
                </div>

                <div class="row">
                    <div class="input-group">
                        <label for="email-personal">Email Personal (used for notifications)</label>
                        <input v-model="user.emailPersonal" type="text" placeholder="" id="email-personal" tabindex="3" required title="please enter a valid zip">
                    </div>

                    <div class="input-group">
                        <label for="address-state">Address State</label>
                        <!-- <input v-model="user.addressState" type="text" placeholder="" id="address-state"> -->
                        <select v-model="user.addressState" placeholder="" id="address-state" style="width: 100%; height: 44px;" tabindex="7" required>
                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="California">California</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="Delaware">Delaware</option>
                            <option value="District of Columbia">District Of Columbia</option>
                            <option value="Florida">Florida</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Louisiana">Louisiana</option>
                            <option value="Maine">Maine</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Michigan">Michigan</option>
                            <option value="Minnesota">Minnesota</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Montana">Montana</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="Nevada">Nevada</option>
                            <option value="New Hampshire">New Hampshire</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="New Mexico">New Mexico</option>
                            <option value="New York">New York</option>
                            <option value="North Carolina">North Carolina</option>
                            <option value="North Dakota">North Dakota</option>
                            <option value="Ohio">Ohio</option>
                            <option value="Oklahoma">Oklahoma</option>
                            <option value="Oregon">Oregon</option>
                            <option value="Pennsylvania">Pennsylvania</option>
                            <option value="Rhode Island">Rhode Island</option>
                            <option value="South Carolina">South Carolina</option>
                            <option value="South Dakota">South Dakota</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="Vermont">Vermont</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Washington">Washington</option>
                            <option value="West Virginia">West Virginia</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="Wyoming">Wyoming</option>
                        </select>
                    </div>

                    <div class="input-group" v-if="user.displayPassword1 == false">
                        <label for="password1-hidden">Password</label>
                        <input v-model="user.password1" type="password" placeholder="" id="password1-hidden" tabindex="11" minlength="8" required>
                        <i @click="toggleDisplayPasswordOne($event)" class="bi bi-eye"></i>
                    </div>
                    <div class="input-group" v-else>
                        <label for="password1-displayed">Password</label>
                        <input v-model="user.password1" type="text" placeholder="" id="password1-displayed" tabindex="11" minlength="8" required>
                        <i @click="toggleDisplayPasswordOne($event)" class="bi bi-eye-slash"></i>
                    </div>
                </div>

                <div class="row">
                    <div class="input-group">
                        <label for="email-business">Email Business</label>
                        <input v-model="user.emailBusiness" type="text" placeholder="" id="email-business" tabindex="4">
                    </div>
                    

                    <div class="input-group">
                        <label for="address-zip">Address Zip</label>
                        <input v-model="user.addressZip" type="text" placeholder="" id="address-zip" tabindex="8" pattern="[0-9]{5}" required>
                    </div>

                    <div class="input-group" v-if="user.displayPassword2 == false">
                        <label for="password2-hidden">Password</label>
                        <input v-model="user.password2" type="password" placeholder="" id="password2-hidden" tabindex="12" minlength="8" required>
                        <i @click="toggleDisplayPasswordTwo($event)" class="bi bi-eye"></i>
                    </div>
                    <div class="input-group" v-else>
                        <label for="password2-displayed">Repeat Password</label>
                        <input v-model="user.password2" type="text" placeholder="" id="password2-displayed" tabindex="12" minlength="8" required>
                        <i @click="toggleDisplayPasswordTwo($event)" class="bi bi-eye-slash"></i>
                    </div>
                </div>

                <div class="row">
                    <div class="input-group">
                        <label for="npn">NPN #</label>
                        <input v-model="user.npn" type="text" placeholder="######" id="npn" tabindex="4">
                    </div>
                    

                    <div class="input-group">
                    </div>

                    <div class="input-group">
                    </div>
                </div>

                <div class="divider"></div>

                <div class="btn-row">
                    <div id="error-message-container" v-if="errorMessage">
                        <img alt="alert-triangle" src="@/assets/alert-triangle.svg">
                        <span id="error-message">{{ errorMessage }}</span>
                    </div>
                    <button type="button" class="btn-white" id="cancel-btn" @click="$router.push({ name: 'Login', params: { email: user.emailPersonal }})">
                        <img src="@/assets/x.svg" alt="x">
                        Cancel
                    </button>
                    <button type="button" class="btn-blue-light" @click="confirm()">
                        Sign Up
                        <img src="@/assets/arrow-right.svg" alt="arrow right"
                            style="filter: invert(1) grayscale(1) brightness(2)">
                    </button>

                </div>
            </form>
        </div>
    </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'SignUp',
    components: {
        // HelloWorld
    },
    data() {
        return {
            errorMessage: '',
            user: {
                firstName: '',
                lastName: '',
                addressStreet: '',
                addressCity: '',
                addressState: '',
                addressZip: '',
                phonePersonal: '',
                phoneBusiness: '',
                emailPersonal: '',
                emailBusiness: '',
                password1: '',
                password2: '',
                displayPassword1: false,
                displayPassword2: false
            }
        }
    },
    methods: {
        async confirm() {
            if (this.user.password1 != this.user.password2) {
                // alert('Passwords do not match');
                this.errorMessage = 'Passwords do not match';
                return;
            }

            try {
                await state.api.call("register", this.$data.user);

                this.$router.push('/my-leads');
            } catch (error) {
                console.error(error);
                if (error.errorMessage === "Your account is not active. Please contact your administrator.") {
                    this.$router.push({ 'name': 'Login', 'params': { 'email': this.user.emailPersonal } });
                } else {
                    this.errorMessage = error.errorMessage;
                }
                return;
            }
        },
        toggleDisplayPasswordOne(event) {
            event.stopPropagation();
            event.preventDefault();

            this.user.displayPassword1 = !this.user.displayPassword1;
        },
        toggleDisplayPasswordTwo(event) {
            event.stopPropagation();
            event.preventDefault();

            this.user.displayPassword2 = !this.user.displayPassword2;
        },
        formatPhoneNumber(phoneNumberType) {
            // Remove all non-digit characters from the phone number
            let formattedNumber = this.user[phoneNumberType].replace(/\D/g, "");

            // Apply the phone number format
            if (formattedNumber.length >= 10) {
                // Format as (XXX) XXX-XXXX
                formattedNumber = `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(3, 6)}-${formattedNumber.slice(6, 10)}`;
            }

            // Update the input value with the formatted number
            console.log(formattedNumber);
            this.user[phoneNumberType] = formattedNumber;
            // return formattedNumber;
        }
    }
}
</script>

<style scoped>

#error-message-container {
    display: flex;
    align-items: center;

    margin-right: auto;
}

#error-message-container img {
    /* max-height: 24px; */
    margin-right: 12px;
}

#error-message {
    align-self: center;
    color: #C56327;
}
.btn-row {
    display: flex;
    justify-content: flex-end;
}

.btn-row button {
    min-width: 112px;
    max-width: 140px;
    width: 10vw;
    margin-left: 24px;
    margin-top: 0;
    justify-content: space-evenly;
}

#cancel-btn {
    color: var(--btn-blue-light);
}

.divider {
    width: 100%;
    border-top: 1px solid #B0CCEE;

    margin-top: 4px;
    margin-bottom: 24px;
}

.form {
    /* width: 100%; */
    width: 68.75vw;
}

@media (max-width:1280px) {
    .form {
        width: 100%;
    }
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    margin-top: 8px;
}

.input-group {
    /* display: flex;
    flex-direction: column; */
    width: 30%;
    height: 72px;

    margin-bottom: 20px;
}

.input-group label {
    height: 28px;
    padding-left: 16px;

    font-size: 12px;
    font-weight: 600;
    color: #707070;
    line-height: 16px;

    display: flex;
    align-items: center;

    user-select: none;
    /* align-self: flex-start; */
    /* justify-content: center; */

}

.input-group input {
    height: 22px;
    padding: 10px 12px;

    width: calc(100% - 26px);

    border-radius: 4px;
    border: 1px solid #CFD8DC;

    /* flex-grow: 1; */
}

.input-group img {
    position: absolute;
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    width: 24px;
    height: 24px;
}

input:focus::placeholder {
    color: transparent;
}

input::placeholder {
    user-select: none;
}

.form i {
    margin-left: -30px;
    cursor: pointer;
    color: var(--btn-blue-dark);
}

#password-hidden {
    margin-left: -14px;
}

#password-displayed {
    margin-left: -14px;
}

#container {
    display: flex;
    flex-direction: column;
}

#top {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    height: 23vh;
    border-bottom: 2px solid var(--color-orange);
}

#top img {
    width: 318px;
}

#bottom {
    /* max-width: 68.75vw; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    flex-grow: 1;
    margin: 0 44px;
}

#form {
    width: 360px;
    margin-top: 3vh;
}

#forgot-password-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2vh;

    font-size: 12px;
}

button {
    display: block;
    margin-top: 24px;
    width: 100%;
    height: 44px;

    border-radius: 10px;

    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}


#login {
    background-color: var(--btn-blue-dark);
    border: 1px solid var(--btn-blue-dark);
}

#signup {
    border: 1px solid #CFD8DC;
    color: var(--btn-blue-dark);
    background-color: white;
}

#login span,
#signup span {
    margin-right: 6px;
}

button:focus {
    outline: 1px solid black !important;
}</style>
  