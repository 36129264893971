import { createRouter, createWebHistory } from 'vue-router';
import MyLeads from '../views/MyLeads.vue';
// import PurchaseLead from '../views/PurchaseLead.vue'
import StandingLeadOrders from '../views/StandingLeadOrders.vue';
import Notifications from '../views/Notifications.vue';
import Admin from '../views/Admin.vue';
import AboutUs from '../views/AboutUs.vue';
import ScheduleTraining from '../views/ScheduleTraining.vue';
import Support from '../views/Support.vue';
import MyAccount from '../views/MyAccount.vue';
import MyPerformance from '../views/MyPerformance.vue';

import Login from '../views/Login.vue';
import SignUp from '../views/SignUp.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Landing from '../views/Landing.vue';

import { state } from '../store/store.js';

const routes = [
	{
		path: '/',
		name: 'Landing',
		component: Login,
	},
	{
		path: '/my-leads',
		name: 'MyLeads',
		component: MyLeads,
	},
	{
		path: '/purchase-lead',
		name: 'PurchaseLead',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/PurchaseLead.vue'),
	},
	{
		path: '/standing-lead-orders',
		name: 'StandingLeadOrders',
		component: StandingLeadOrders,
	},
	{
		path: '/my-account',
		name: 'MyAccount',
		component: MyAccount,
	},
	{
		path: '/my-performance',
		name: 'MyPerformance',
		component: MyPerformance,
	},
	{
		path: '/notifications',
		name: 'Notifications',
		component: Notifications,
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
	},
	{
		path: '/about-us',
		name: 'AboutUs',
		component: AboutUs,
	},
	// {
	// 	path: '/about-us',
	// 	name: 'Landing',
	// 	component: Landing,
	// },
	{
		path: '/schedule-training',
		name: 'ScheduleTraining',
		component: ScheduleTraining,
	},
	{
		path: '/support',
		name: 'Support',
		component: Support,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/sign-up',
		name: 'SignUp',
		component: SignUp,
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: ForgotPassword,
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: ResetPassword,
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

// If a user attempts to access a route that is not in the validRoutes array, redirect them to the MyLeads page.
// Eventually once there is a back end, split this into two functions, one for logged in users and one for logged out users.
const validRoutes = [
	'/my-leads',
	'/purchase-lead',
	'/standing-lead-orders',
	'/notifications',
	// hamburgur menu ONLY routes
	'/admin',
	'/about-us',
	'/schedule-training',
	'/support',
	'/my-performance',
	'/my-account',
	// Needs to be navigation guarded once auth is implemented
	'/sign-up',
	'/login',
	'/forgot-password',
	'/reset-password',
	'/'
];

const publicRoutes = [
	'/sign-up',
	'/login',
	'/forgot-password',
	'/reset-password',
	'/'
];

router.beforeEach(async (to, from) => {
	if (!validRoutes.includes(to.path)) {
		// redirect the user to the login page
		return { name: 'MyLeads' };
	}

	//Protect Purchase Routes
	if (to.path === '/purchase-lead' ||
		to.path === '/standing-lead-orders') {
		if (state.user.cannotPurchase) {
			return { name: 'MyLeads' };
		}
	}

	//Protection for Reset Password Route
	if (to.path === '/reset-password') {
		if (!to.query.email && !to.query.key) {
			return { name: 'Login' };
		}else {
			let res = await state.api.call("checkKeyExpiration", { email: to.query.email, key: to.query.key });
			// console.log(res);
			if (res === 'G') {
				return true;
			}else {
				return { name: 'Login' };
			}
		}
	}

	if (publicRoutes.includes(to.path)) {
		return true;
	}

	if (!state.user.rid) {
		// redirect the user to the login page
		return { name: 'Login' };
	}else if (!state.user.npn) {
		state.user.npn = "";
		document.querySelector('dialog._npn').showModal();
	}
});

export default router;
