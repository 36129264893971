<script setup>
    import { state } from '@/store/store.js';
	import CurrencyInput from '@/components/CurrencyInput.vue'; 
</script>

<template>
	<div class="modal">
		<div class="details-wrapper">
			<div id="header">
				<div style="flex: 1;">
					<button
						class="btn-white"
						id="close-btn"
						@click="closeMenu()"
					>	
						<img alt="close menu icon" src="@/assets/close-icon.svg">
					</button>
				</div>
				
				<button
					class="btn-white"
					@click="exportDetailsToCSV()"
				>	
					CSV
				</button>
				<button
					class="btn-white"
					@click="downloadPDF()"
				>	
					PDF
				</button>

				<div class="divider"></div>

				<button class="btn-blue-light" style="margin-right: 9px;" @click="printDetails()">
					<img id="printer" alt="printer icon" src="@/assets/printer.svg">
					Print
				</button>
			</div>
			<div id="content">
				<div class="col" id="col1">
					<div class="details-group">
						<div class="details-title">First Name</div>
						<div class="details-text wt-700">{{ leadData.firstName || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">Last Name</div>
						<div class="details-text wt-700">{{ leadData.lastName || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">State, ZIP</div>
						<div class="details-text">{{ leadData.state || "-" }}, {{ leadData.zip || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">County</div>
						<div class="details-text">{{ leadData.county || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">City</div>
						<div class="details-text" style="text-transform: uppercase;">-</div>
					</div>

					<div class="details-group">
						<div class="details-title">Address</div>
						<div class="details-text">{{ leadData.address || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">Home Phone</div>
						<div class="details-text">{{ leadData.homePhone || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">Work Phone</div>
						<div class="details-text">{{ leadData.workPhone || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">Cell Phone</div>
						<div class="details-text">{{ leadData.cellPhone || "-" }}</div>
					</div>
				</div>
				<div class="col" id="col2">
					<div class="details-group">
						<div class="details-title">Age</div>
						<div class="details-text">{{ leadData.age || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">Spouse Age</div>
						<div class="details-text">{{ leadData.spouseAge || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">Lendershort</div>
						<div class="details-text">{{ leadData.lenderShort || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">Mortshort</div>
						<div class="details-text wt-700">{{ leadData.mortShort || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">Batch #</div>
						<div class="details-text">{{ leadData.batchNo || "-" }}</div>
					</div>

					<div class="details-group">
						<div class="details-title">Record ID</div>
						<div class="details-text">{{ leadData.rid }}</div>
					</div>

					<div v-if="isSold">
						<div class="details-group">
							<div class="details-title">Annual Premium Sold</div>
							<div class="details-text">${{annualPremiumSold}}</div>
						</div>
					</div>
					

					<!-- if editing.. -->
					<div v-if="editing == true" style="display: flex; flex-direction: column; align-items: flex-start;">
						<div class="input-group">
							<label for="2" class="details-title" style="border-bottom: none; margin-bottom: 2px;">Annual Premium Sold</label>
							<CurrencyInput v-model="annualPremiumSold" :options="{ currency: 'USD' }" />
						</div>
						<div class="text-checkbox-wrapper" v-if="leadData.burned != 1" style="margin-top:5px">
                            <div class="text-checkbox">
                                <input id="burnLead" name="burnLead" v-model="burnLead" type="checkbox" />
                            </div>
                            <label for="burnLead">Disallow this lead from future reselling through Victory Leads ($10)</label>
                        </div>

						<div style="display: flex; justify-content: space-between; width: 100%; margin-top: 8px;">
							<button @click="cancelEditing()" class="btn-white" style="width: 45%; margin: 0; justify-content: center;">Cancel</button>
							<button @click="updateLeadStatus('Sold')" class="btn-blue-light" style="width: 45%; margin: 0; justify-content: center">Save</button>
						</div>
					</div>

					<!-- else if not editing.. -->
					<div v-else>
						<button v-if="!isSold" class="btn-blue-dark" style="margin-top: 22px;" @click="editing = true">
							Mark as Sold
						</button>
						<button v-if="isSold" class="btn-white" style=" margin: 0; margin-top: 22px;" @click="unmarkAsSold()">
							Unmark as Sold
						</button>
					</div>
					
					
				</div>
			</div>
		</div>
		<div class="document-wrapper">
			<object id="pdf" type="application/pdf" width="100%" height="100%" :data="pdfUrl">
				Unable to open the PDF file.
			</object>
		</div>
	</div>
	<div id="curtain" @click="closeMenu()"></div>
	<dialog>
		<div>
			<div class="ccfields -sticky">
				<div class="_existingcards">
					<div class="input-group">
						<label>Saved Cards</label>
						<select id="save-cards" v-model="cards.selected">
							<optgroup label="Saved Cards">
								<template v-for="card in cards.saved" :key="card.id">
								<option v-if="card.type != 'loading' && card.type != 'empty'" :value="card.id" :disabled="card.expired">{{ card.type.toUpperCase() }} ending in: {{ card.last4 }} ({{ card.exp }}) {{ card.expired ? ' - Expired ':'' }}{{ card.default ? '(Default)' : '' }}</option>
								<option v-else-if="card.type == 'empty'" disabled value="">No saved cards.</option>
								<option v-else disabled value="">...Loading Cards</option>
								</template>
							</optgroup>
							<optgroup label="New Card">
								<option value="new">New Card</option>
							</optgroup>
						</select>
					</div>
				</div>
				<div class="_newcard" v-if="cards.selected == 'new'">
					<div class="input-group">
						<label>Cardholder Name</label>
						<div id="card-holder-name"></div>
					</div>

					<div class="input-group">
						<label>Card Number</label>
						<div id="card-number" :class="cardType"></div>
					</div>

					<div class="input-group-row">
						<div class="input-group" style="max-width: calc(33.3333% - 15px);">
							<label>Exp. Date</label>
							<div id="expiration-date"></div>
						</div>

						<div class="input-group" style="max-width: calc(33.3333% - 15px);">
							<label>CVV</label>
							<div id="cvv"></div>
						</div>

						<div class="input-group" style="max-width: calc(33.3333% - 15px);">
							<label>Postal Code</label>
							<div id="postal-code"></div>
						</div>
					</div>
					<div class="text-checkbox-wrapper">
						<div class="text-checkbox">
							<input v-model="saveCard" type="checkbox" name="saveCard" id="saveCard">
						</div>
						<label for="saveCard" class="agree-paragraph">Save Card for Future Use</label>
					</div>
				</div>
			</div>
			<footer>
				<button @click="showCCModal = false" class="btn-white">Cancel</button>
				<button @click="processCC()" class="btn-blue-light">Finalize Payment</button>
			</footer>
		</div>
	</dialog>
</template>

<script>
export default {
	name: 'LeadDetails',
	props: {
		// msg: String,
		leadData: Object
	},
	data() {
		return {
			isSold: false,
			editing: false,
			annualPremiumSold: 0,
			pdfUrl: '',
			burnLead: false,
			showCCModal: false,
			validCard: false,
			newLeadStatus: '',
			cardType: '',
			cards: {
				selected: '',
				saved: [
					{
						id: 0,
						type: 'loading',
					}
				]
			}
		}
	},
	async mounted() {
		// console.log(this.leadData);
		this.isSold = this.leadData.isSold;
		this.annualPremiumSold = this.leadData.annualPremium;
		this.pdfUrl = this.getPDFUrl();

		//Load Saved Cards
		let customerCards = await state.api.call("getCustomerCards", { customerID: `vmcust-${state.user.rid}` });
		// console.log(customerCards);
		if (!customerCards.cards.length) {
			this.cards.saved = [{
				id: 0,
				type: 'empty'
			}];
			this.cards.selected = 'new';
			this.validCard = false;
		}else {
			// customerCards.cards[3].expired = true;
			this.cards.saved = customerCards.cards;
			// console.log(this.cards.saved);
			for (let card of customerCards.cards) { //Select default card if it is not expired
				if (card.default && !card.expired) {
					this.cards.selected = card.id;
					this.validCard = true;
					break;
				}
			}
			if (!this.cards.selected) { //Select card if default card is expired
				for (let card of customerCards.cards) {
					if (!card.expired) {
						this.cards.selected = card.id;
						this.validCard = true;
						break;
					}
				}
			}
		}
	},
	methods: {
		getPDFUrl() {
			// console.log(window.location.origin.split(":80")[0] + "/api/getPDF?rid=" + this.leadData.rid + '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&page=1&view=FitH');
			return window.location.origin.split(":80")[0] + "/api/getPDF?rid=" + this.leadData.rid + '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&page=1&view=FitH';
		},
		exportDetailsToCSV() {
			// Generate CSV
			let lead = this.leadData;
			let csvContent = "Name,Address,Home Phone,Work Phone,Cell Phone,Age,Spouse Age,Lendershot,Mortshort,Batch No.,Record ID,Annual Premium Sold\n";
			csvContent += `${lead.fullName},"${lead.address}",${lead.homePhone},${lead.workPhone},${lead.cellPhone},${lead.age},${lead.spouseAge},${lead.lenderShort},${lead.mortShort},${lead.batchNo},${lead.rid},${this.annualPremiumSold}\n`;

			var file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }),
				filename = `${lead.lastName}-lead-details.csv`;
			if (window.navigator.msSaveOrOpenBlob) // IE10+
				window.navigator.msSaveOrOpenBlob(file, filename);
			else { // Others
				var a = document.createElement("a"),
					url = URL.createObjectURL(file);
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				setTimeout(function () {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}, 0);
			}
		},
		downloadPDF() {
			let pdf = window.open(window.location.origin.split(":80")[0] + "/api/getPDF?rid=" + this.leadData.rid + '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&page=1&view=FitH', '_blank');
			return pdf;
		},
		printDetails() {
			let pdf = this.downloadPDF();
			pdf.print();
		},
		closeMenu() {
			state.leadsDetails.toggle();
		},
		async setLeadStatus(newstatus) {
			let response = await state.api.call("setLeadStatus", {
				leadIDs: this.leadData.rid,
				status: newstatus,
				userID: state.user.rid,
				annualPremium: this.annualPremiumSold
			});
			let updatedLeads = response.details,
				status = updatedLeads[this.leadData.rid];

			if (status === 'Sold') {
				this.isSold = true;
			}else {
				this.isSold = false;
			}
			this.$emit('leadstatusupdate', {
				rid: this.leadData.rid,
				status: status,
				annualPremium: this.annualPremiumSold
			});
			this.editing = false;
		},
		async updateLeadStatus(newstatus) {
			if (this.burnLead && this.annualPremiumSold) {
				this.newLeadStatus = newstatus;
				this.showCCModal = true;
				return;
			}
			if (this.annualPremiumSold) {
				await this.setLeadStatus(newstatus);
			}else {
				state.flash({
					type: 'error',
					message: `Please indicate an "Annual Premium" for this sale`
				});
			}
		},
		cancelEditing() {
			this.editing = false;
		},
		async unmarkAsSold() {
			this.isSold = false;
			this.editing = false;
			this.annualPremiumSold = 0;

			// let response = await state.api.call("markLeadAsSold", {leadID: this.leadData.rid, isSold: false});
			let response = await state.api.call("setLeadStatus", {leadIDs: this.leadData.rid, status: 'Active', userID: state.user.rid});
			let updatedLeads = response.details,
				status = updatedLeads[this.leadData.rid];

			if (status === 'Sold') {
				this.isSold = true;
			}else {
				this.isSold = false;
			}
			this.$emit('leadstatusupdate', {
				rid: this.leadData.rid,
				status: status
			});
		},
		async getToken() {
			try {
				let tokenCall = await state.api.call('generateBTToken', { customerID: `vmcust-${state.user.rid}` }),
					token = tokenCall.token;
				return token;
			} catch (error) {
				alert(error.message);
			}
		},
		async loadCC() {
			try {
				this.cardType = '';
				if (window.hostedFieldsInstance) {
					await window.hostedFieldsInstance.teardown();
				}
				let token = await this.getToken(),
					clientInstance = await braintree.client.create({
						authorization: token
					});
				window.BTDataCollector = await braintree.dataCollector.create({
					client: clientInstance
				});
				var options = {
					client: clientInstance,
					styles: {
						'input': {
							'padding': '10px 12px',
							'color': '#000'
						},
						'input.invalid': {
							'color': 'red'
						}
					},
					fields: {
						cardholderName: {
							container: '#card-holder-name',
							placeholder: 'Jane Smith'
						},
						number: {
							container: '#card-number',
							placeholder: '4111 1111 1111 1111'
						},
						cvv: {
							container: '#cvv',
							placeholder: '123'
						},
						expirationDate: {
							container: '#expiration-date',
							placeholder: '12/2030'
						},
						postalCode: {
							container: '#postal-code',
							placeholder: '12345'
						}
					}
				};
				window.hostedFieldsInstance = await braintree.hostedFields.create(options);
				// console.log(window.hostedFieldsInstance);
				hostedFieldsInstance.on('validityChange', async (e) => {
					await this.verifyCCValidity();
				});
				hostedFieldsInstance.on('cardTypeChange', async (e) => {
					await this.ccChange(e);
				});
			} catch (error) {
				console.error(error);
			}
		},
		async ccChange(e) {
			if (e.cards.length === 1) {
				this.cardType = e.cards[0].type;
			}else {
				this.cardType = '';
			}
		},
		async verifyCCValidity() {
			try {
				let fields = window.hostedFieldsInstance.getState().fields,
					valid = true;
				for (let field in fields) {
					if (!fields[field].isValid) {
						valid = false;
						break;
					}
				}
				if (valid) {
					this.validCard = true;
				} else {
					this.validCard = false;
				}
			} catch (error) {
				console.error(error);
			}
		},
		async processCC() {
			try {
				let data = {};
				if (this.cards.selected === 'new') { //New Card
					let tokenize = await window.hostedFieldsInstance.tokenize(),
						nonce = tokenize.nonce,
						deviceData = null;

					data = {
						lead: this.leadData.rid,
						customerID: `vmcust-${state.user.rid}`,
						saveCard: false
					};
					if (window.BTDataCollector) {
						deviceData = await window.BTDataCollector.getDeviceData();
					}
					if (nonce) {
						data.nonce = nonce;
					}
					if (deviceData) {
						data.deviceData = deviceData;
					}
					if (this.saveCard) {
						data.saveCard = true;
					}
					// console.log(data);
				}else { //Saved Card
					data = {
						lead: this.leadData.rid,
						cardToken: this.cards.selected,
						customerID: `vmcust-${state.user.rid}`
					};
				}
				// console.log(data);
				let result = await state.api.call('burnLead', data);
				// console.log(result);
				if (result.error) {
					state.flash({
						type: 'error',
						message: result.error
					});
				} else {
					state.flash({
						type: 'success',
						message: `Lead now disallowed from future reselling.`
					});
					this.showCCModal = false;
					await this.setLeadStatus(this.newLeadStatus);
					this.$emit('leadburnupdate', {
						rid: this.leadData.rid,
						burned: 1
					});
				}
			} catch (error) {
				console.error(error);
				state.flash({
					type: 'error',
					message: error.message
				});
			}
		},
	},
	watch: {
		showCCModal() {
			if (this.showCCModal) {
				document.querySelector('dialog').showModal();
			}else {
				document.querySelector('dialog').close();
				this.newLeadStatus = '';
			}
		},
		'cards.selected': function() {
			if (this.cards.selected === 'new') {
				this.loadCC();
				this.validCard = false;
			}else if (this.cards.selected !== 'new') {
				this.validCard = true;
			}
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#pdf {
	/* max-height: calc(100vh - 5px); */
	border: none;
}
.details-group {
	width: 200px;
	min-height: 72px;
	margin-bottom: 8px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.details-title {
	color: #707070;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;

	border-bottom: 1px solid #E0E0E0;
	padding: 8px 12px 4px 12px;
}

.details-text {
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	text-align: left;

	padding: 10px 12px;
}
.details-wrapper {
	width: 480px;
	padding: 0 16px;

	display: flex;
	flex-direction: column;
}

.col {
	flex: 1;

	display: flex;
	flex-direction: column;
}

#col1 {
	border-right: 1px solid rgba(0, 0, 0, 0.08);
}

#col2 {
	padding-left: 16px;
}

.details-wrapper #content {
	display: flex;
	flex: 1;
	margin-bottom: 16px;
}

#header {
	display: flex;
	align-items: center;

	height: 44px;
	margin-top: 16px;
	margin-bottom: 44px;
}

#header button {
	margin: 0 4px;
}

#close-btn {
	padding: 10px;
}


#close-btn img {
	margin-left: 0;
}

#printer {
	filter: invert(1) grayscale(1) brightness(2);
	margin-right: 8px;
}

.document-wrapper {
	min-width: 700px;
	
	border-left: 1px solid black;

	overflow: hidden;
}

.divider {
	border-left: 1px solid #B0CCEE;
	margin: 0 24px;
	height: 24px;
}

#curtain {
	position: fixed;
	z-index: 998;
	background: rgba(0,0,0,0.5);
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.modal {
	background: white;
    position: fixed;
	z-index: 999;
    right: 0;
    top: 0;
    height: 100vh;
	display: flex;
	overflow-y: scroll;
}
.text-checkbox-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.text-checkbox {
		width: 20px;
	}
	label {
		font-size: 15px;
		cursor: pointer;
		padding: 10px;
		width: calc(100% - 40px);
		text-align: left;
	}
}
</style>
<style>
	dialog {
		z-index: 99999;
		border: 0;
		outline: 0;
		width: 80%;
		max-width: 600px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;

		* {
			-webkit-box-sizing: inherit;
			box-sizing: inherit;
		}

		select {
			width: 100%;
		}

		form {
			width: 100%;
			height: auto;
		}

		.input-group > div {
			height: 44px;
			width: 100%;
			border-radius: 4px;
			border: 1px solid #CFD8DC;
			background-color:  #fff;
		}

		input {
			padding: 10px 12px;
			color: #000;
		}

		footer {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			button {
				margin: 1rem;
			}
		}

		&::backdrop {
			background-color: rgba(0,0,0,.2);
		}
	}
</style>
