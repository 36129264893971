<script setup>
    import { state } from '../store/store.js';
</script>

<script>
	export default {
		name: 'HamburgerMenu',
		props: {
			msg: String,
		},
		data: () => ({
			year: new Date().getFullYear()
		}),
		methods: {
			closeMenu() {
				state.hamburgerMenu.toggle();
			}
		},
	};
</script>

<template>
	<div class="container">
		<div
			class="nav-btn"
			@click="closeMenu()"
			style="margin-top: 16px"
		>	
			Close Menu
			<img alt="close menu icon" src="@/assets/close-icon.svg">
		</div>

		<div class="divider"></div>

		<router-link
			to="/my-leads"
			class="nav-btn"
			@click="closeMenu()"
		>
			My Leads
			<img alt="my leads icon" src="@/assets/my-leads.svg">
		</router-link>
		<router-link
			to="/purchase-lead"
			class="nav-btn"
			@click="closeMenu()"
			v-if="!state.user.cannotPurchase"
		>
			Purchase Lead
			<img alt="purchase lead icon" src="@/assets/purchase-lead.svg">
		</router-link>
		<router-link
			to="/standing-lead-orders"
			class="nav-btn"
			@click="closeMenu()"
			v-if="!state.user.cannotPurchase"
		>
			Standing Lead Orders
			<img alt="standing lead order icon" src="@/assets/standing-lead-order.svg">
		</router-link>
		
		<router-link
			to="/my-performance"
			class="nav-btn"
			@click="closeMenu()"
		>
			My Performance
			<img alt="percent icon" src="@/assets/percent.svg">
		</router-link>

		<router-link
			to="/notifications"
			class="nav-btn"
			@click="closeMenu()"
			v-if="state.user.role === 'dev'"
		>
			Notifications
			<img alt="notifications icon" src="@/assets/notifications.svg">
		</router-link>

		<router-link
			to="/my-account"
			class="nav-btn"
			@click="closeMenu()"
		>
			My Account
			<img alt="user setup icon" src="@/assets/user-setup.svg">
		</router-link>

		<div class="divider"
			v-if="state.user.role === 'dev' || state.user.role === 'admin'"></div>

		<router-link
			to="/admin"
			class="nav-btn"
			@click="closeMenu()"
			v-if="state.user.role === 'admin'"
		>
			Admin
			<img alt="about us icon" src="@/assets/cog.svg">
		</router-link>

		<router-link
			to="/about-us"
			class="nav-btn"
			@click="closeMenu()"
			v-if="state.user.role === 'dev'"
		>
			About Us
			<img alt="about us icon" src="@/assets/about-us.svg">
		</router-link>
		<router-link
			to="/support"
			class="nav-btn"
			@click="closeMenu()"
			v-if="state.user.role === 'dev'"
		>
			Support
			<img alt="support icon" src="@/assets/support.svg">
		</router-link>
		<router-link
			to="/schedule-training"
			class="nav-btn"
			@click="closeMenu()"
			v-if="state.user.role === 'dev'"
		>
			Schedule Training
			<img alt="schedule training icon" src="@/assets/schedule-training.svg">
		</router-link>

		<div class="divider"></div>

		<div id="copyright-text">
			<span>©️ {{ year }}. Victory Marketing and Development LLC</span>
		</div>
		<!-- Copyright info -->
	</div>
	<div id="curtain" @click="closeMenu()"></div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
	background: white;

    display: flex;
    flex-direction: column;
	align-items: flex-end;

    position: absolute;
	z-index: 10;

    right: 0;
    top: 0;
    height: 100vh;
	width: 250px; /*  234px to match figma literal */

	padding: 0 24px;
	
	transform: translateX(0%);
	transition: transform 0.3s ease-in;
}

.container a, .container div {
	text-align: right;

	margin: 10px 0px;
}
.divider {
	width: 100%;
	height: 1px;
	background: var(--color-divider);
}

#copyright-text {
	font-size: 14px;
	font-weight: 400;
	color: #757575;

	width: 170px;
	flex-grow: 1;

	display: flex;
	align-items: flex-end;

	padding-bottom: 12px;
}

#curtain {
	position: absolute;
	z-index: 5;

	background: rgba(0,0,0,0.5);

	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

}
</style>
