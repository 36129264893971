import axios from "axios";

const BASE_URL = window.location.origin.split(":80")[0] + "/api";
// const BASE_URL = "https://victoryleads.net/api";
// const BASE_URL = "http://localhost/api";

let callQueue = [];
export default class PortalAPI {
	constructor(app, baseURL = BASE_URL) {
		this.app = app;
		this.baseURL = baseURL;

		axios.defaults.withCredentials = true;

		return {
			call: (cls, func, params, autoUpdateData) => {
				return this.call(cls, func, params, autoUpdateData);
			},
			get: this.generateProxy("get"),
			post: this.generateProxy("post"),
			put: this.generateProxy("put"),
			patch: this.generateProxy("patch"),
			del: this.generateProxy("delete")
		}
	}

	generateProxy(method) {
		return new Proxy(this, {
			get: (target, prop) => {
				if (target[prop] === undefined) {
					return (params, autoUpdateData) => this.callAPI(method, prop, params, autoUpdateData);
				}
				return target[prop];
			}
		});
	}

	verifyResponse(response, autoUpdateData = true) {
		if(response.data.errorCode === 99){
			window.localStorage.setItem("isLoggedIn", 0);
			this.app.user.rid = "";
			throw { name: "Error", message: "Response from Server: '" + response.data.errorDetails + "'", errorMessage: response.data.errorText, errorDetails: response.data.errorDetails };
		} else if (response.data.errorCode !== 0) {
			// let message = "Response from Server: '" + response.data.errorDetails + "'";
			let message = response.data.errorText;
			// this.app.toast?.error(message);

			throw { name: "Error", message: message, errorMessage: message, errorDetails: response.data.errorDetails };
		}

		if (!response.data.data) return {};

		if (response.data.time){
			this.app.serverTimeOffset = new Date() - response.data.time;
		}

		return response.data.data;
	}

	async wait(interval){
		return new Promise((resolve, reject) => {
			window.setTimeout(() => {
				resolve();
			}, interval);
		});
	}

	async queueCall(url, params) {
		return new Promise((resolve, reject) => {
			callQueue.push({ url, params, resolve, reject });

			if (callQueue.length === 1) this.processCallQueue();
		});
	}

	async processCallQueue(){
		let nextInQueue = callQueue[0];

		if (nextInQueue) {
			// console.log(this.app.loadingBar);
			// this.app.loadingBar?.start();

			this.app.isAPIProcessing = true;

			try {
				let response = await axios.post(nextInQueue.url, nextInQueue.params);

				if (nextInQueue.resolve) nextInQueue.resolve(response);
				
				// this.app.loadingBar?.finish();
			} catch(e) {
				nextInQueue.reject(e);
				// this.app.loadingBar?.error();
			}

			callQueue = callQueue.splice(1, 1);

			this.app.isAPIProcessing = false;

			this.processCallQueue();
		}
	}

	async call(func, params, autoUpdateData) {
		let response;

		while(!response){
			try {
				response = await this.queueCall(`${this.baseURL}/${func}`, params);
				// response = await axios.post(`${this.baseURL}/${cls}/${func}`, params);
			} catch (e) {
				response = e.response;
			}

			// Retry if no response from server
			if (!response || response.data.errorCode === undefined) {
				await this.wait(1000);
				// TODO: Add callID in case server gets multiple of the same call?
				return await this.call(...arguments);
			}

			response = this.verifyResponse(response, autoUpdateData);
		}

		return response;
	}
}