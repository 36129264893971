<script setup>
import { store, state } from './store/store.js';

import HamburgerMenu from './components/HamburgerMenu.vue';
import NPNModal from './components/NPNModal.vue';


</script>

<template>
  <div id="app-container" :class="state.currentView ? state.currentView:''">
    <div class="indeterminate-progress-bar" v-if="state.isAPIProcessing">
      <div class="indeterminate-progress-bar__progress"></div>
    </div>
    <ul class="_flashmessages">
      <template v-for="[key, flash] in state.flashMessages" :key="flash">
        <li :id="key" :class="[flash.type ? `-${flash.type}` : '', flash.status ? flash.status : '']">{{ flash.message }}</li>
      </template>
    </ul>

    <div id="nav-container" v-if="!routesWithHiddenNav.includes(currentRouteName())">
      <img id="navbar-logo" alt="Victory logo" src="@/assets/logo.svg">
      <router-link class="nav-btn" to="/my-leads">
        My Leads
        <img alt="my leads button" src="@/assets/my-leads.svg">
      </router-link>
      <router-link class="nav-btn" to="/purchase-lead" v-if="!state.user.cannotPurchase">
        Purchase Lead
        <img alt="purchase lead button" src="@/assets/purchase-lead.svg">
      </router-link>
      <router-link class="nav-btn" to="/standing-lead-orders" v-if="!state.user.cannotPurchase">
        Standing Lead Orders
        <img alt="standing lead order button" src="@/assets/standing-lead-order.svg">
      </router-link>
      <router-link class="nav-btn" to="/notifications" v-if="state.user.role === 'dev'">
        Notifications
        <img alt="notifications button" src="@/assets/notifications.svg">
      </router-link>
      <div id="hamburger-menu-button-container">
        <img class="nav-btn" alt="hamburger menu" src="@/assets/hamburger.svg" @click="state.hamburgerMenu.toggle()">
      </div>

    </div>
    <router-view class="app-body" />

    <HamburgerMenu v-if="state.hamburgerMenu.display"></HamburgerMenu>
    <NPNModal></NPNModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routesWithHiddenNav: ['Login', 'Landing', 'SignUp', 'ForgotPassword', 'ResetPassword'],
      currentView: 'home'
    }
  },
  mounted() {
    state.currentView = 'home';
    if (this.$router.path && !state.user.rid) {
      this.$router.push('/login');
    }
  },
  methods: {
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: "config-variable", Helvetica, Arial, sans-serif;
  font-variation-settings: "slnt" 0, "wght" 400, "wdth" 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */

  b, strong {
    font-variation-settings: "wght" 500, "wdth" 100;
    letter-spacing: 0.5px;
  }

  em, i {
    font-variation-settings: "slnt" 9, "wght" 400, "wdth" 100;
    font-style: normal !important;
  }

  b, strong {
    em, i {
      font-variation-settings: "slnt" 9, "wght" 500, "wdth" 100;
      letter-spacing: 0.5px;
      font-style: normal !important;
    }
  }

  em, i {
    b, strong {
      font-variation-settings: "slnt" 9, "wght" 500, "wdth" 100;
      letter-spacing: 0.5px;
      font-style: normal !important;
    }
  }
}

button,
input,
textarea {
  font-family: "config-variable", Helvetica, Arial, sans-serif;
  font-variation-settings: "slnt" 0, "wght" 500, "wdth" 100;
  letter-spacing: 0.5px;
}

:root {
  --btn-blue-dark: #1E4C83;
  --btn-blue-light: #2A6BB8;
  --color-content-bg: #F1F2F3;
  --color-divider: #F4C9AC;
  --color-orange: #E77721;
  --color-orange-dark: #C56327;
  --color-red: #d80932;
  --color-green: #0fd41f;
}

.wt-400 {
  font-weight: 400;
}

.wt-500 {
  font-weight: 500;
}

.wt-600 {
  font-weight: 600;
}

.wt-700 {
  font-weight: 700;
}

.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.input-group {
  /* display: flex;
    flex-direction: column; */
  width: 100%;
  height: 72px;

  margin-bottom: 20px;
}

.input-group label {
  height: 28px;
  padding-left: 16px;

  font-size: 12px;
  font-weight: 600;
  color: #707070;
  line-height: 16px;

  display: flex;
  align-items: center;

  user-select: none;
  /* align-self: flex-start; */
  /* justify-content: center; */

}

.input-group input {
  height: 22px;
  padding: 10px 12px;

  width: calc(100% - 26px);

  border-radius: 4px;
  border: 1px solid #CFD8DC;

  /* flex-grow: 1; */
}

.input-group img {
  position: absolute;
  /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
  width: 24px;
  height: 24px;
}

input:focus::placeholder {
  color: transparent;
}

input::placeholder {
  user-select: none;
}

.input-group-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-group-row .input-group {
  width: 48%;

}

.details-group {
  width: 200px;
  min-height: 72px;
  margin-bottom: 8px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.details-title {
  color: #707070;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;

  text-align: left;

  border-bottom: 1px solid #E0E0E0;
  padding: 8px 12px 4px 12px;
}

.details-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;

  padding: 10px 12px;
}


body {
  background-color: var(--color-content-bg);
}
#app-container {
  height: 100vh;

  display: flex;
  flex-direction: column;
}

.app-body {
  flex-grow: 1;
}



#nav-container {
  height: 76px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  width: calc(100vw - 48px);
  background-color: #fff;
}

#navbar-logo {
  max-height: 65px;
  /* flex-basis: 30%; */

  margin-right: auto;
}


.nav-btn {
  padding: 10px 16px;
  border-radius: 12px;
  background-color: white;
  color: var(--btn-blue-dark);

  text-transform: uppercase;
  text-decoration: none;
  -webkit-user-drag: none;
  font-size: .938rem;
  /* px bad? */
  /* 15px is .938rem */

  margin: 0 1%;

  display: flex;
  align-items: center;

}

button {
  cursor: pointer;
}

select {
  /* Define your custom styles here */
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 200px;
  font-size: 14px;
}


.btn-blue-light {
  border-radius: 10px;
  color: white;
  background: var(--btn-blue-light);
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border: none;
  height: 44px;
  font-size: 16px;
}

.btn-blue-light:hover {
  background: var(--btn-blue-dark);
}

.btn-blue-dark {
  border-radius: 10px;
  color: white;
  background: var(--btn-blue-dark);
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border: none;
  height: 44px;
  font-size: 16px;
}

.btn-white {
  border-radius: 10px;
  color: var(--btn-blue-light);
  background: white;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #B0CCEE;
  height: 44px;
  font-size: 16px;

}

.btn-white:hover {
  background: #B0CCEE;
  border-color: var(--btn-blue-light)
}

.btn-orange {
  border-radius: 10px;
  color: white;
  background: var(--color-orange);
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid var(--color-orange);
  height: 44px;
  font-size: 16px;
}

.btn-orange img {
  filter: brightness(0) invert(1);
  margin-right: 4px;
}

.btn-orange:hover {
  background: var(--color-orange-dark);
  border-color: var(--color-orange-dark);
}

.btn-orange-border {
  border-radius: 10px;
  color: var(--color-orange);
  background: white;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid var(--color-orange);
  height: 44px;
  font-size: 16px;
}

.btn-orange-border img {
  filter: invert(55%) sepia(23%) saturate(6813%) hue-rotate(355deg) brightness(101%) contrast(81%);
}

.btn-orange-border:hover img {
  filter: brightness(0) invert(1);
}

.btn-orange-border:hover {
  background: var(--color-orange-dark);
  border-color: var(--color-orange-dark);
  color: white;
}

.btn-disabled,
button:disabled {
  border-radius: 10px;
  color: #9E9E9E;
  background: white;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid #9E9E9E;
  height: 44px;
  font-size: 16px;
  pointer-events: none;
}

.disabled {
  pointer-events: none;
}

object.disabled {
  opacity: 0.5;
}

.nav-btn img {
  margin-left: 12px;
}

.nav-btn:hover {
  background-color: #E5EEF9;
}

.nav-btn:visited {
  text-decoration: none;
}

#hamburger-menu-button-container {
  max-height: 39px;
  cursor: pointer;
}

#hamburger-menu-button-container img:hover {
  background-color: #E5EEF9;
}

/* #nav-container a {
  font-weight: bold;
  color: #2c3e50;
} */

a.router-link-exact-active {
  color: white;
  background-color: var(--btn-blue-dark);
}

a.router-link-exact-active img {
  filter: invert(1) grayscale(1) brightness(2);
}

a.router-link-exact-active:hover {
  background-color: var(--btn-blue-dark);
}

.vue-recycle-scroller__item-view.hover:not(.selected) {
  background: white;
}



/* Loading Bar CSS Animation */
/* https://csslayout.io/indeterminate-progress-bar/ */
/* ************************************************ */
.indeterminate-progress-bar {
  /* Color */
  background-color: #d1d5db;

  /* Size */
  height: 0.5rem;

  position: fixed;
  overflow: hidden;

  width: 100%;
}

.indeterminate-progress-bar__progress {
  /* Color */
  background-color: #E77721;

  /* Rounded border */
  border-radius: 9999px;

  /* Absolute position */
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;

  /* Move the bar infinitely */
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
  from {
    left: -50%;
  }

  to {
    left: 100%;
  }
}

._flashmessages {
  display: block;
  position: fixed;
  top: 55px;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;

  & li {
    display: block;
    max-width: 600px;
    width: 90%;
    padding: 8px 15px;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
    filter: drop-shadow(0 2px 5px rgba(0,0,0,0.1));
    transition: transform 250ms cubic-bezier(1,0,0,1);
    transform: scale(0);

    &.-show {
      transform: scale(1);
    }

    & a {
      color: #fff
    }

    &.-error {
      background-color: var(--color-red);
    }
    &.-alert {
      background-color: var(--color-orange);
    }
    &.-success {
      background-color: var(--color-green);
    }
  }
}

#form {
  max-width: 90%;
}

table {
  margin-bottom: 70px;
}
.vt-pagination .pagination {
  margin-top: -60px !important;
}

.container {
  width: calc(100vw - 48px);
}

.filter-btn {
  position: relative;

  &:hover::after {
    opacity: 1 !important;
  }
}
.filter-btn:not(.-no-dropdown)::after {
  content: attr(data-description);
  display: block;
  width: 300px;
  padding: 15px 20px;
  background-color: #fff;
  border-radius: 10px;
  left: 0;
  top: calc(100% + 5px);
  z-index: 99;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease-in-out;
  color: #333;
  font-size: 14px;
  text-align: left;
  text-transform: none;
  line-height: 1.5;
  font-weight: 400;
  filter: drop-shadow(0 5px 10px rgba(0,0,0,0.1));
}

.tippy-content {
  font-family: "config-variable", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-variation-settings: "slnt" 1, "wght" 500, "wdth" 100;
}

#app-container {
  @media screen and (max-width: 980px) {
    & #nav-container > .nav-btn {
      display: none;
    }
  }

  @media screen and (max-width: 1225px), (hover: none)  {
    & .document-wrapper {
      display: none;
    }
  }

  @media screen and (max-width: 768px),
         only screen and (min-device-width: 834px) 
          and (max-device-width: 834px) 
          and (orientation: portrait) 
          and (-webkit-min-device-pixel-ratio: 2) {
    & table thead .v-th,
    &.my-account table thead .v-th,
    &.my-account table thead th:not(.checkbox-wrapper) {
      display: none;
    }
    & table thead .checkbox-wrapper {
      width: 100%;
      max-width: 100%;
      border-top: 1px solid #ccc;
      flex-wrap: wrap;
      flex: auto;
      align-items: flex-start;
      height: auto;
      padding: 5px;
      margin-left: 0;
      margin-right: 0;

      &::before {
        content: 'Select All';
        display: block;
        text-align: center;
        width: 100%;
      }
    }
    & table tbody {
      border-top: 1px solid #ccc;
    }
    & table tbody tr {
      flex-wrap: wrap;
      height: auto;
      line-height: auto;

      &.vt-selected > td:not(.checkbox-wrapper):not(._empty)::before {
        background-color: rgba(0,0,0,0.1);
      }

      & > td:not(.checkbox-wrapper):not(._empty) {
        width: 100%;
        max-width: 100%;
        padding-left: calc(50% + 20px);
        position: relative;
        padding-top:10px;
        padding-bottom:10px;
        flex-wrap: wrap;
        margin: 0;

        &:not(:last-of-type) {
          border-bottom: 1px solid #ccc;
        }

        span {
          width: 100%;
          display: block;
          margin-left: 0;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
      & > td:not(.checkbox-wrapper):not(._empty)::before {
        content: attr(data-title);
        width: calc(50% - 40px);
        height: 100%;
        padding: 0 20px;
        display: flex;
        align-items: center;
        background-color: rgba(0,0,0,0.05);
        border-right: 1px solid #ccc;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        font-size: 13px;
        text-transform: uppercase;
        color: #4d4d4d;
        font-weight: 600;
        background-image: none;
        aspect-ratio: initial;
        border-radius: 0;
      }
      & > td.checkbox-wrapper {
        width: 100%;
        max-width: 100%;
        justify-content: center;
        padding-top:10px;
        padding-bottom:10px;
        border-bottom: 1px solid #ccc;
        margin-left: 0;
        margin-right: 0;
      }
      & > td._empty {
        justify-content: center;
      }
    }

    /**
     * Map Pages
     */
    &.map .container {
      flex-wrap: wrap;
      width: calc(100vw - 48px);

      .v-table {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }

      .footer {
        width: calc(100vw - 72px);
        margin-left: -24px;
      }
     }
    & .left-wrapper,
    & .right-wrapper {
      width: 100% !important;
    }
    & .center-wrapper,
    & .right-wrapper {
      margin-top: 20px;
    }
    & .right-wrapper {
      overflow-y: visible;
      overflow-x: visible;
    }
    & .step-three {
      overflow: visible;
    }
    & .selected-state-section {
      height: auto;
    }
    & .total-price {
      margin: 0 10px;
    }
    & .selected-list {
      padding-left: 0;
    }

    & #step-four-content {
      width: 100%;
      flex-wrap: wrap;

      .left-wrapper {
        padding-right: 0;
        border-right: 0;
        border-bottom: 1px solid #b0ccee;
        padding-bottom: 30px;
        margin-bottom: 10px;
      }
      .right-wrapper {
        padding-left: 0;
      }
    }
    & .step-four .button-row {
      margin-top: 30px;
      padding-top: 20px;
      width: 100%;
    }

    /**
    * My Account
    */
    &.my-account #left-wrapper {
      width: 100%;
      padding-right: 0;
      border-right: none;
      order: 1;
      margin-bottom: 30px;
    }
    &.my-account #right-wrapper {
      width: 100%;
      padding-left: 0;
      order: 0;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #b0ccee;
    }
    &.my-account #update-password {
      max-width: 100%;
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    & .footer .divider,
    & .footer .divider + .btn-blue-light {
      display: none;
    }
    & ._bulkEdit {
      flex-wrap: wrap;
      justify-content: center;

      & .input-group,
      & > span {
        width: 100%;
        margin-bottom: 15px;
      }

      & button {
        &:first-of-type {
            margin-left: initial;
        }
      }
    }
  }

  @media screen and (max-width: 530px) {
    & .container {
      width: calc(100vw - 48px);
    }
    & .details-wrapper {
      width: 100%;
    }
    &.my-account {
      table button {
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    &.home .container {
      & .divider,
      & .btn-blue-light {
        display: none;
      }
    }
    & #content,
    & .col {
      flex: auto;
      flex-wrap: wrap;
    }
    & .col {
      width: 100%;
      padding: 0;
    }
    & #col1 {
      border-right: 0;
    }
    & #col2 {
      padding-left: 0;
      padding-bottom: 100px;
    }
    & .details-group {
      width: 100%;
    }
    & .details-title {
      width: calc(100% - 24px);
    }
    & .footer {
      width: calc(100vw - 30px) !important;
      padding: 0 15px;
    }
    & .selected-count {
      font-size: 15px;
      line-height: 1.2;
      text-align: left;
    }
    & #select-state-text {
      font-size: 22px;
      line-height: 1.3;
    }
    &.my-account .row {
      flex-wrap: wrap;

      .input-group {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 400px) {
    
  }
}

</style>
